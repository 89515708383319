import { Action, CreditProposalType, MetaDataType } from "src/types";
import { CREDIT_PROPOSAL_TYPES } from "./types";

export interface CreditProposalsStateType {
    data?: CreditProposalType[]
    creditProposal?: CreditProposalType
    isLoading: boolean
    isCreating: boolean;
    meta: MetaDataType
    isDownloading: boolean
    abacusProduct?: any
    abacusProducts?: {
        id: string
        name: string
        organization_id: string
        product_type_id: string
        worker: string
        visibility: string
        status: string
        period: string
        product_type_name: string
    }[]
    validations?: {
        [key in keyof CreditProposalType]?: string[]
    },
    requestStatus?: 'downloading' | 'sending' | 'downloadingAmortizationTable'
}

const INITIAL_STATE: CreditProposalsStateType = {
    isLoading: false,
    isDownloading: false,
    isCreating: false,
    meta: {
        page: 1,
        perPage: 25,
        total: 0,
        totalPages: 1
    },
}

export function creditProposalsReducer(state: CreditProposalsStateType = INITIAL_STATE, action: Action): CreditProposalsStateType {
    const { type, payload } = action
    switch (type) {

        case CREDIT_PROPOSAL_TYPES.SET: {
            return {
                ...state,
                [payload.key]: payload.value
            }
        }

        case CREDIT_PROPOSAL_TYPES.GET_ALL: {
            return {
                ...state,
                isLoading: true,
                meta: {
                    ...state.meta,
                    page: payload.params?.page,
                    perPage: payload.params?.perPage,
                },
            }
        }

        case CREDIT_PROPOSAL_TYPES.SET_ALL: {
            return {
                ...state,
                isLoading: false,
                data: payload.creditProposals,
                meta: {
                    page: payload.meta?.current_page || 1,
                    perPage: payload.meta?.per_page || 25,
                    totalPages: payload.meta?.total_pages || 1,
                    total: payload.meta?.total || 0
                },
            }
        }

        case CREDIT_PROPOSAL_TYPES.DOWNLOAD: {
            return {
                ...state,
                requestStatus: 'downloading'
            }
        }

        case CREDIT_PROPOSAL_TYPES.SEND_EMAIL:
        case CREDIT_PROPOSAL_TYPES.DOWNLOAD: {
            return {
                ...state,
                requestStatus: 'sending'
            }
        }

        case CREDIT_PROPOSAL_TYPES.DOWNLOAD_AMORTIZATION_TABLE: {
            return {
                ...state,
                requestStatus: 'downloadingAmortizationTable'
            }
        }

        case CREDIT_PROPOSAL_TYPES.GET_ONE:
        case CREDIT_PROPOSAL_TYPES.UPDATE_STATUS: {
            return {
                ...state,
                isLoading: true
            }
        }

        case CREDIT_PROPOSAL_TYPES.SET_ONE: {
            return {
                ...state,
                creditProposal: payload?.creditProposal,
                isLoading: false
            }
        }

        case CREDIT_PROPOSAL_TYPES.UPDATE_STATUS: {
            return {
                ...state,
                isLoading: false
            }
        }

        default:
            return state
    }
}