import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
	container: {
		padding: '45px 76px 45px 30px',
		[theme.breakpoints.down('sm')]: {
			padding: '32px 16px 78px'
		}
	},
	emptyState: {
		paddingTop: 75
	},
	card: {
		borderRadius: 10,
		backgroundColor: '#fff',
		padding: '24px 26px'
	}
}));