import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    container: {
        '& ul': {
            margin: 0,
            '& li': {
                padding: 0
            }
        }
    },
    reason: {
        padding: '12px 0',
    },
    reasonTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: 10,
        paddingBottom: 8,
        '& > p': {
            fontSize: 16,
            fontWeight: 'bold'
        },
        '& svg': {
            minWidth: 24
        }
    },
    reasonDescription: {
        fontWeight: 400,
        '& > a': {
            color: '#4AAAFF',
            textDecoration: 'none'
        }
    }
}))