import { ReactNode } from "react"
import { Grid, GridProps, Typography, TypographyProps } from "@mui/material"
import { useStyles } from "./Card.styles"

interface CardProps extends GridProps {
    title?: any
    description?: string

    children: ReactNode | ReactNode[]

    titleAction?: ReactNode

    /** Put the title outside the card instead of inside */
    titleOutside?: boolean
    showDivider?: boolean
    showBorder?: boolean
    hideHeader?: boolean

    contentGridProps?: GridProps
    titleContainerProps?: GridProps
    titleProps?: TypographyProps
}

export function Card({
    title,
    titleOutside,
    description,
    titleAction,
    children,
    showDivider,
    className,
    hideHeader,
    showBorder,
    titleContainerProps,
    titleProps,
    contentGridProps,
    ...rest
}: CardProps) {
    const { classes, cx } = useStyles()
    const { className: titleContainerClassName, ...restTitleContainerProps } = titleContainerProps || {}

    function RootWrapper({ children }: { children: ReactNode }) {
        if ((title && titleOutside && !hideHeader)) {
            return <Grid container> {children} </Grid>
        }
        return <>{children}</>
    }

    function CardWrapper({ children }: { children: ReactNode }) {
        if ((title && titleOutside && !hideHeader)) {
            return <Grid item xs={12}> {children} </Grid>
        }
        return <>{children}</>
    }

    function CardTitle() {
        return (
            <Grid
                item
                xs={12}
                sx={{ mb: titleOutside ? '24px' : 0 }}
                {...restTitleContainerProps}
                className={cx(classes.titleContainer, {
                    [classes.spaceBetween]: Boolean(titleAction),
                    [classes.divider]: showDivider,
                    [classes.paddingNone]: titleOutside
                }, titleContainerClassName)}
            >
                {(title || description) && (
                    <div className={classes.flex}>
                        {title && (
                            <Typography
                                variant="h3"
                                {...titleProps}
                                className={cx(classes.title, titleProps?.className)}
                            >
                                {title}
                            </Typography>
                        )}

                        {description && <Typography> {description} </Typography>}
                    </div>
                )}

                {titleAction}
            </Grid>
        )
    }

    return (
        <RootWrapper>
            {(title && titleOutside && !hideHeader) && <CardTitle />}

            <CardWrapper>
                <Grid
                    container
                    {...rest}
                    className={cx(
                        classes.card,
                        { [classes.border]: showBorder },
                        className,
                    )}
                >
                    {(title && !titleOutside && !hideHeader) && <CardTitle />}

                    <Grid
                        item
                        xs={12}
                        {...contentGridProps}
                        className={cx(
                            classes.contentContainer,
                            contentGridProps?.className,
                        )}
                    >
                        {children}
                    </Grid>
                </Grid>
            </CardWrapper>
        </RootWrapper>
    )
}