import { useState } from "react"
import { Avatar, Box, BoxProps, Divider, IconButton, MenuItem, Typography } from "@mui/material"
import { LogoutIcon } from "src/assets/icons"
import { Customer, Organization } from "src/types"
import { getFullName, initialsFor, useIsMobile } from "src/helpers"
import { useStyles } from "./styles"
import { Menu, OptionsMenu } from "src/components"

interface CustomerAvatarProps {
    organization?: Organization
    customer?: Customer
    logout: () => void
    wrapperProps?: BoxProps
}

export function CustomerAvatar({ organization, customer, wrapperProps, logout }: CustomerAvatarProps) {
    const { classes } = useStyles(organization?.theme)()
    const isMobile = useIsMobile()
    const [logoutMenuAnchorEl, setLogoutMenuAnchorEl] = useState<HTMLDivElement | null>()

    return (
        <>
            <Menu
                anchorEl={logoutMenuAnchorEl}
                open={Boolean(logoutMenuAnchorEl)}
                onClose={() => setLogoutMenuAnchorEl(null)}
            >
                <MenuItem onClick={logout}>
                    <Typography variant='body1'>
                        Cerrar sesión
                    </Typography>
                </MenuItem>
            </Menu>

            <Box
                {...wrapperProps}
                display='flex'
                justifyContent='center'
                alignItems='center'
                gap={'24px'}
            >
                <Box display='flex' alignItems='center'>
                    <Avatar
                        className={classes.avatar}
                        onClick={e => isMobile && setLogoutMenuAnchorEl(e.currentTarget)}
                    >
                        <Typography className={classes.text}>
                            {initialsFor(getFullName(customer)) || '🙂'}
                        </Typography>
                    </Avatar>

                    <Typography className={classes.customerNameText}>
                        {`${customer?.first_name || ''} ${customer?.last_name || ''}`}
                    </Typography>
                </Box>

                <Divider orientation="vertical" className={classes.divider} />

                <IconButton className={classes.logoutButton} onClick={logout}>
                    <LogoutIcon />
                </IconButton>
            </Box>
        </>
    )
}