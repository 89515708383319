export type CreditProposalStatusType = 'accepted' | 'rejected'

export const translateCreditProposalStatus: { [key in CreditProposalStatusType]: string } = {
    'accepted': 'Aceptada',
    'rejected': 'Rechazada'
}

export const confirmationModalTitle: { [key in CreditProposalStatusType | '']: string } = {
    'accepted': 'Propuesta de crédito',
    'rejected': 'Propuesta de crédito',
    '': '-'
}

export const confirmationModalDescription: { [key in CreditProposalStatusType | '']: string } = {
    'accepted': '¿Estás de acuerdo en aceptar esta propuesta de crédito?',
    'rejected': '¿Estás de acuerdo en rechazar esta propuesta de crédito?',
    '': '-'
}

export const confirmationModalAction: { [key in CreditProposalStatusType | '']: string } = {
    'accepted': 'Aceptar',
    'rejected': 'Rechazar',
    '': '-'
}