import { Box, Button, Grid, Typography } from "@mui/material";
import { Dialog } from "src/components";
import { useStyles } from "./styles";
import { useState } from "react";

import { useDispatch } from "react-redux";
import { applicationActions } from "src/store/application/actions";
import { CoaccreditedIcon } from "src/assets/icons";

interface CoaccreditModalProps {
	applicationId: string
	open: boolean
	onClose: () => void
}

export function CoaccreditModal({ applicationId, open, onClose }: CoaccreditModalProps) {
	const { classes } = useStyles()
	const dispatch = useDispatch()

	const [step, setStep] = useState(1)
	const [answers, setAnswers] = useState({
		is_partner: false,
		will_contribute: false,
	})

	const stepTittle: { [key: string]: string } = {
		step1: '¿El coacreditado es tu cónyuge?',
		step2: '¿El coacreditado va a aportar ingresos?',
	}

	function handleAnswer(answer: string) {
		if (step === 1) {
			setAnswers({ ...answers, is_partner: answer === 'yes' })
			setStep(2)
		}
		if (step === 2) {
			const _answers = { ...answers, will_contribute: answer === 'yes' }
			setAnswers(_answers)
			dispatch(applicationActions.createApplicationByEventTrigger(applicationId, 'create-coacredited-application', _answers))
			onClose()
		}
	}

	return (
		<Dialog
			open={open}
			title=""
			onClose={onClose}
			maxWidth="sm"
			dialogTitleProps={{
				className: classes.dialogTitle
			}}
		>
			<Grid container justifyContent="center">
				<Box className={classes.imgContainer}>
					<CoaccreditedIcon />
				</Box>
				<Typography className={classes.title}>{stepTittle[`step${step}` as keyof typeof stepTittle]}</Typography>
				<Grid container justifyContent="center">
					<Button
						className={classes.button}
						variant="outlined"
						color="primary"
						onClick={() => handleAnswer('no')}
					>
						No
					</Button>
					<Button
						className={classes.button}
						variant="contained"
						color="primary"
						onClick={() => handleAnswer('yes')}
					>
						Sí
					</Button>
				</Grid>
			</Grid>
		</Dialog>
	)
}