import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Alert, Loading, StagePage } from "src/components";
import { PaymentStatusAlert } from "./PaymentStatusAlert";
import { Box, Grid, Typography } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { applicationPaymentsActions } from "src/store/payments/actions";
import { customTranslatePaymentSessionStatus } from "src/helpers/payments";

import { PaymentAside } from "./Aside";
import { PaymentLineItemTable } from "./Table";
import { PaymentTotals } from "./Totals";
import { useStyles } from "./styles";
import { useQueryParams } from "src/helpers";

export function ApplicationPaymentDetailsPage() {
    const { applicationId, paymentId } = useParams()
    const { classes } = useStyles()
    const dispatch = useDispatch()
    const { only_one } = useQueryParams()

    const payment = useSelector(state => state.payments.payment)
    const isLoading = useSelector(state => state.payments.isLoadingOne)
    const isPaymentPending = customTranslatePaymentSessionStatus(payment) === 'pendiente'
    const paymentUrl = payment?.short_link || payment?.url

    useEffect(() => {
        if (paymentId)
            dispatch(applicationPaymentsActions.getOne(paymentId))
    }, [paymentId])

    return (
        <StagePage
            title="Detalle de pago"
            breadcrumbs={only_one ? [
                { name: 'Inicio', to: '/' },
                { name: 'Detalle de pago' }
            ] : [
                { name: 'Inicio', to: '/' },
                { name: 'Pagos', to: `/home/applications/${applicationId}/payments` },
                { name: 'Detalle de pago' }
            ]}
        >
            <Loading isLoading={isLoading} variant="skeleton">

                <Grid container spacing={3}>

                    {isPaymentPending && (
                        <Grid item xs={12}>
                            <Alert
                                actionProps={{
                                    title: 'Pagar avalúo',
                                    linkProps: {
                                        to: paymentUrl!,
                                    }
                                }}
                            >
                                Paga el avalúo de la propiedad para continuar con la solicitud
                            </Alert>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <PaymentStatusAlert payment={payment} />
                    </Grid>

                    {isPaymentPending && (
                        <Grid item xs={12}>
                            <Typography> Haz tu pago haciendo clic en el botón "Pagar avalúo"; serás redirigido al procesador de pagos de Stripe. </Typography>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Grid container>

                            <Grid item xs={12} md={4} className={classes.dataCard}>
                                <PaymentAside payment={payment} />
                            </Grid>

                            <Grid item xs={12} md={8} container className={classes.detailsCard}>
                                <Grid container justifyContent="flex-end">
                                    <Grid item xs={12} md={8} className={classes.detailsData}>
                                        <Box>
                                            <PaymentLineItemTable
                                                data={payment?.line_items || [{
                                                    id: payment?.id || "",
                                                    amount_discount: 0,
                                                    amount_total: payment?.amount_total || 0,
                                                    description: payment?.metadata?.payment_type || payment?.payment_type || "-",
                                                    quantity: 1,
                                                    amount_subtotal: payment?.amount_subtotal || 0,
                                                    amount_tax: 0,
                                                    currency: payment?.currency || "",
                                                }]}
                                            />
                                        </Box>
                                        <Box>
                                            <PaymentTotals payment={payment} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>

            </Loading>
        </StagePage>
    )
}