export const applicationStatusTitle: { [key: string]: string } = {
    rejected: 'Lo sentimos, por el momento no es posible continuar con tu solicitud',
    in_review: 'Estamos revisando tu expediente',
    unreviewed: 'Estamos revisando tu expediente',
    approved: '¡Tu solicitud fue preaprobada!',
    preapproved: '¡Tu solicitud fue preaprobada!'
}

export const applicationStatusDescription: { [key: string]: string } = {
    rejected: 'Tu solicitud ha sido rechazada por motivos que no cumplen con nuestras políticas',
    in_review: 'Un ejecutivo va a revisar tu expediente y en unas horas te informaremos sobre tus resultados vía mensaje de texto y correo electrónico.',
    unreviewed: 'Un ejecutivo va a revisar tu expediente y en unas horas te informaremos sobre tus resultados vía mensaje de texto y correo electrónico.',
}