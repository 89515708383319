import { FormBuilder, StagePage } from "src/components";
import { fields, initialValues } from "./fields";
import { RootState } from "src/store";
import { useSelector, useDispatch } from "react-redux";
import { customerActions } from "src/store/customer/actions";
import { useIsMobile, segment } from "src/helpers";
import { useEffect } from "react";
import { getValidationSchema } from "@kredimx/form-builder";
import { Card } from "@mui/material";
import { useStyles } from "./styles";

export function ContactData() {
	const dispatch = useDispatch();
	const isMobile = useIsMobile();
	const { classes, cx } = useStyles()
	const customer = useSelector((state: RootState) => state.auth.customer);

	useEffect(() => {
		segment.track('Customer', 'Viewed', 'ContactData')
	}, [])

	function onSubmit(values: any) {
		dispatch(customerActions.update(values));
		segment.track('Customer', 'Updated', 'ContactData')
	}

	return (
		<StagePage
			title="Contacto"
			className={classes.stagePage}
			containerProps={{
				md: 8
			}}
			breadcrumbs={[
				{
					name: "Perfil",
					to: "/home/profile"
				},
				{
					name: "Contacto",
					to: ""
				},
			]}
		>
			<Card className={classes.card} elevation={0}>
				<FormBuilder
					fields={fields}
					onSubmit={onSubmit}
					validationSchema={getValidationSchema(fields)}
					initialValues={customer.id ? customer : initialValues}
					submitCaption="Guardar cambios"
					hideCancel
					hideHelpers
					actionContainerProps={{
						justifyContent: isMobile ? 'center' : 'flex-end',
					}}
					submitButtonProps={{
						hidden: true,
						dataCy: 'submit-button',
						className: classes.submitButton
					}}
					cancelButtonProps={{
						className: classes.deleteButton
					}}
					hideButton
					disableOptionalLabel
					showValidationCheckIcon={false}
				/>
			</Card>
		</StagePage>
	);
}
