import { ComponentType, Fragment } from "react";
import { Typography, Breadcrumbs, Hidden, AppBar, Grid } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import LeftIcon from "@mui/icons-material/ChevronLeft";
import { useStyles } from "./Sidebar.styles";
import { useState } from "react";
import { getApplicationStatusIcon, hasInstalledApp, navigate, useRouteMatch } from "src/helpers";
import { RootState } from "src/store";
import _ from "lodash";
import { PageType, TrackingResourceType } from "src/helpers/segment";
import { useSelector } from "react-redux";
import { ApplicationWorkflowSection } from "src/types";
import { Link } from "src/components";

export interface SidebarBreadcrumb {
	to: string;
	name?: string;
}

export interface SidebarItem {
	id?: string;
	icon?: any;
	name?: string;
	/** Use this if you need to declare params */
	path?: string;
	/** Use this to redirect replacing the params */
	to: string;
	isMenuItem?: boolean;
	component?: ComponentType
	status?: string;
	isParent?: boolean;
	items?: SidebarItem[];
	sortOrder?: number;
	page?: PageType;
	resource?: TrackingResourceType;
	disabled?: boolean;
	/** Use for validate if a app is installed */
	app?: 'abacus';
	onClick?: () => void;
}

export interface SidebarProps {
	title?: string;
	items?: SidebarItem[];
	text?: string;
	breadcrumbs?: SidebarBreadcrumb[];
	onItemClick?: (item: any) => void;
	exactRoutes?: boolean;
	showStatusIcon?: boolean;
	titleLink?: SidebarBreadcrumb;
	hover?: boolean;
	isLoading?: boolean;
	/** If using cypress this prop should is true */
	isTesting?: boolean;
	/** next section of appliction */
	nextSection?: ApplicationWorkflowSection
	openSectionDrawer?: () => void
	/** Is updating an application */
	isUpdating?: boolean
	sidebarProps?: any;
	selectedItemClassname?: string;
	isSummary?: boolean;
	sectionsIndex?: 'progress_line' | 'sections'
	currentSection?: ApplicationWorkflowSection
}

/** Use to show a menu using items params */
export function ApplicationSidebar({
	title,
	items,
	text,
	breadcrumbs,
	onItemClick,
	exactRoutes,
	titleLink,
	hover,
	isLoading,
	isTesting,
	nextSection,
	isUpdating,
	sidebarProps,
	selectedItemClassname,
	isSummary,
	sectionsIndex,
	currentSection,
	openSectionDrawer
}: SidebarProps) {
	const [open, setOpen] = useState(false)
	const { classes, cx } = useStyles();
	const [submenuOpen, setSubmenuOpen] = useState(0);
	const organization = useSelector((state: RootState) => state.organization)
	const match = useRouteMatch()

	const currentSectionNumber = (currentSection?.sort_order || 0) + 1
	const totalSections = items?.length || 0
	const showSummary = nextSection === null && match.url.includes('summary')
	const filteredItems = items?.filter(i => sectionsIndex == 'progress_line' ? !i.disabled : true)
	const showProgressLine = sectionsIndex == 'progress_line'

	function onClick(item: SidebarItem, index: number) {
		if (item.isParent) {
			setSubmenuOpen(index);

			if ((item.items?.length || 0) > 0) {
				navigate.to(_.orderBy(item.items, 'sortOrder')[0].to!);
			}

		} else {
			if (_.isFunction(item.onClick)) {
				item.onClick()
			}
			if (typeof (onItemClick) === 'function') {
				onItemClick(item);
			}
			setOpen(false);

			if (index !== submenuOpen) {
				setSubmenuOpen(0);
			}
		}
	}

	const TitleWrapper = titleLink?.to ? Link : Fragment;
	const titleWrapperProps = titleLink?.to ? { to: titleLink.to || '' } : {};

	// added because "Hidden" component interfered with unit tests 
	const TestingWrapper = isTesting ? Fragment : Hidden;
	const testingWrapperProps = isTesting ? {} : { mdDown: true };

	const { className: sidebarClassName, ...restSidebarProps } = sidebarProps || {};

	const content = (
		<>
			{filteredItems?.map((item: SidebarItem, index: number) => {
				const Component = (item.isParent || item.disabled) ? Fragment : Link;
				const props = (item.isParent || item.disabled) ? {} : { to: item.to };
				const isSelected = (exactRoutes && (!!!item.isParent || true))
					? window.location.pathname === encodeURI(item.to.replace('?from=summary', '').replace('?from=stage_summary', ''))
					: window.location.pathname.includes(encodeURI(item.to?.toLowerCase()))

				const hasAppInstalled = hasInstalledApp(organization?.installed_apps || {}, item.app!)

				if (item.app && !hasAppInstalled) {
					return null;
				}

				if (item.isMenuItem)
					return (
						/*@ts-ignore*/
						<Component key={index} {...props}>
							<div
								onClick={() => item.disabled ? null : onClick(item, index)}
								className={cx(classes.item, {
									[classes.hover]: hover && !item.disabled,
									[classes.selectedItem]: isSelected,
									[selectedItemClassname || '']: isSelected
								})}
							>
								<div className={classes.itemIconContainer}>
									<Typography className={cx({ [classes.disabledText]: item.disabled })} >
										{item.name}
									</Typography>
								</div>
								<div className={classes.statusIconContainer}>
									{getApplicationStatusIcon(item.status || '', isSummary)}
								</div>
							</div>
						</Component>
					)
			})}
		</>
	)

	return (
		<>
			<TestingWrapper {...testingWrapperProps}>
				<div className={cx(classes.sidebar, sidebarClassName)} {...restSidebarProps}>
					<div className={classes.sidebarContent}>
						{breadcrumbs && (
							<Breadcrumbs className={classes.breadcrumbs}>
								{breadcrumbs.map((breadcrumb: SidebarBreadcrumb, index: number) => (
									index === breadcrumbs.length - 1 ? (
										<Link to={breadcrumb.to}> {breadcrumb.name || ''} </Link>
									) : (
										<Typography color="textPrimary"> {breadcrumb.name} </Typography>
									)
								))}
							</Breadcrumbs>
						)}
						{/*@ts-ignore*/}
						<TitleWrapper {...titleWrapperProps}>
							{isLoading ? <Fragment /> : (
								<Typography
									className={classes.title}
									style={{
										paddingLeft: titleLink?.to ? 30 : 37,
										paddingRight: titleLink?.to ? 30 : 37,
									}}
								>
									{titleLink?.to && <LeftIcon color="primary" />}
									{title}
								</Typography>
							)}
						</TitleWrapper>
						{isLoading ? skeletons : content}
					</div>
					{text && <Typography className={classes.help} dangerouslySetInnerHTML={{ __html: text }} />}
				</div >
			</TestingWrapper>

			<div className={cx({ [classes.hideOnDesktop]: !showProgressLine })}>
				<AppBar
					elevation={0}
					position="fixed"
					onClick={openSectionDrawer}
					className={cx(classes.appbar, {
						[classes.hideOnDesktop]: showProgressLine,
					})}
				>
					<div className={classes.toolbar}>
						<div className={classes.progressAndTitle}>
							<Typography className={classes.sectionNumber}>
								{!(isLoading || isUpdating) && currentSectionNumber}
							</Typography>
							<Typography className={classes.title}>
								{isLoading || isUpdating ? <Skeleton width={250} /> : (
									showSummary ? "Resumen" : title
								)}
							</Typography>

						</div>
					</div>
				</AppBar>
				<div
					className={cx(classes.progressLineContainer, {
						[classes.hideOnDesktop]: !showProgressLine,
						[classes.progressLineDesktop]: showProgressLine,
					})}
				>
					<div
						id="progress-line"
						className={classes.progressLine}
						style={{
							width: showSummary
								? '100%'
								: `${(currentSectionNumber / totalSections) * 100}%`
						}}
					/>
				</div>
			</div>
		</>
	)
}

const skeletons = (
	<Grid container style={{ padding: '0px 37px' }}>
		<Skeleton
			variant="rectangular"
			height={30}
			width="100%"
			style={{ marginBottom: 40 }}
		/>
		{Array(8).fill(null).map((_, index) => (
			<Skeleton
				key={index}
				variant="rectangular"
				height={30}
				width="100%"
				style={{ marginBottom: 16 }}
			/>
		))}
	</Grid>
)