import { useState } from "react";
import { MultiBankAccountGroupUpload } from "@kredimx/form-builder";
import { UploadComponentProps } from "..";
import { snackbarActions } from "src/store/snackbar/actions";
import _ from "lodash"
import { Bugsnag } from "src/helpers";

export function UploadComponentMultiBankAccount({
    approval,
    dispatch,
    actionable,
    application,
    setIsUploading,
    applicationService,
    handleClose,
    hideLabel,
    isUploading
}: UploadComponentProps) {
    const [file, setFile] = useState<any>(null)
    //Dirty way to extract index from bank_statement_label (bank_statement_1)
    const label = approval?.field?.config?.bank_statement_label || approval?.field?.config?.scoring_config?.bank_statement_label || ''
    const index = label[label.length - 1] - 1 || 0
    const locations = !_.isEmpty(approval?.field?.config?.scoring_config?.locations)
        ? approval?.field?.config?.scoring_config?.locations
        : approval?.field?.config?.locations || []

    async function handleUpload(index: number, files: any[]) {
        try {
            setIsUploading(true)
            let response: ResponseType

            const formData = new FormData()
            formData.append('bank_account_attachment[label]', label)
            formData.append('bank_account_attachment[file]', files[0])
            formData.append('bank_account_attachment[verification_id]', approval.verification_id || '')

            if (locations.length > 0) {
                locations.forEach((location: string) => {
                    formData?.append('bank_account_attachment[locations][]', location)
                })
            }

            response = await applicationService.updateCustomPath(`${application.id!}/bank_accounts/${actionable?.id!}/update_attachment`, formData)

            setTimeout(async () => {
                dispatch(snackbarActions.add('success', 'Documento enviado, la sincronización puede tardar unos minutos'))
                handleClose?.()
                setIsUploading(false)
            }, 8000)

        } catch (e) {
            console.log("error", e)
            setIsUploading(false)
            dispatch(snackbarActions.add('error', 'Lo sentimos, ha ocurrido un error'))
            Bugsnag.notify(e as any)
        }
    }

    return (
        <MultiBankAccountGroupUpload
            label={hideLabel ? '' : approval?.field?.label}
            name={approval?.field?.name || ''}
            parentIndex={0}
            index={index}
            bankAccount={{ institution_name: "" }}
            file={file}
            onRemove={index => { }}
            onDrop={handleUpload}
            downloadAttachment={attachment => { }}
            md={12}
            isCustomUploading={isUploading}
        />
    )
}