import { useEffect } from 'react';
import { StagePage, EmptyState, Loading, Table } from 'src/components';
import { Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from 'src/store';
import { useStyles } from "./styles";
import { customerActions } from 'src/store/customer/actions';
import { segment } from 'src/helpers';
import { Add } from '@mui/icons-material';
import { addressColumns } from './columns';

export function Addresses() {
	const { classes, cx } = useStyles();
	const dispatch = useDispatch();
	const { addresses, isLoading } = useSelector((state: RootState) => state.customer);
	const getAddresses = () => dispatch(customerActions.getAddresses());

	useEffect(() => {
		getAddresses();
		segment.track('Customer', 'Viewed', { view: 'Addresses' })
	}, [])

	return (
		<StagePage
			title="Direcciones"
			breadcrumbs={[
				{
					name: "Perfil",
					to: "/home/profile"
				},
				{
					name: "Direcciones",
					to: ""
				},
			]}
			className={classes.container}
			titleContainerProps={{
				justifyContent: 'space-between'
			}}
		>
			<Loading isLoading={isLoading}>
				<Grid container className={classes.card}>
					{addresses?.length == 0 ? (
						<EmptyState
							text="No tienes direcciones registradas"
							size="medium"
							className={classes.emptyState}
							style={{ padding: '24px 0' }}
						/>
					) : (
						<Table
							data={addresses}
							columns={addressColumns}
							isLink
							getHref={address => `/home/profile/addresses/${address.id}`}
						/>
					)}

				</Grid>
			</Loading>

		</StagePage>
	)
}