import { ScoringSocketDataType } from "src/helpers";
import { Customer, Action } from "src/types";
import { WORKFLOW_TYPES } from "../workflow/types";
import { AUTH_TYPES } from "./types";

export interface AuthState {
    isAuthenticated: boolean;
    customer: Customer;
    token: string;
    email: string;
    error: boolean;
    logRocketSession: string;
    isSegmentLoaded: boolean;
    scoringSocketData?: ScoringSocketDataType
    scoringProductApplicationId?: string
    pathToRedirect?: string
}

const INITIAL_STATE: AuthState = {
    isAuthenticated: false,
    customer: {
        addresses: [],
        employments: []
    },
    token: '',
    email: '',
    error: false,
    logRocketSession: '',
    isSegmentLoaded: false
}

export const authReducer = (state: AuthState = INITIAL_STATE, action: Action): AuthState => {
    const { type, payload } = action;
    switch (type) {

        case AUTH_TYPES.SET: {
            return {
                ...state,
                [payload.name]: payload.value
            }
        }

        case AUTH_TYPES.SUCCESS_LOGIN: {
            return {
                ...state,
                isAuthenticated: true,
                token: payload.token,
                error: false,
            }
        }

        case AUTH_TYPES.SET_EMPLOYMENTS: {
            return {
                ...state,
                customer: {
                    ...state.customer,
                    employments: payload.employments || []
                }
            }
        }

        case AUTH_TYPES.LOGOUT: {
            return INITIAL_STATE;
        }

        case WORKFLOW_TYPES.LOGIN_AGAIN: {
            return {
                ...INITIAL_STATE,
                email: payload.email
            }
        }

        case AUTH_TYPES.SET_LOGROCKET_SESSION: {
            return {
                ...state,
                logRocketSession: payload.url
            }
        }

        case AUTH_TYPES.SET_SCORING_DATA: {
            return {
                ...state,
                scoringSocketData: payload.data
            }
        }

        case AUTH_TYPES.SET_CUSTOMER: {
            return {
                ...state,
                customer: payload.customer
            }
        }

        default:
            return state;
    }
}