import { ActionableType, Application, ApplicationStatusType } from "src/types";
import { APPLICATION_EVALUATOR_FIELDS } from "./evaluatorFields/types";
import { APPLICATION_TYPES } from "./types";
import { ApplicationStateType } from "./reducer";

export * from "./categories/actions"
export * from "./rejectedDocuments/actions"
export * from "./evaluatorFields/actions"

type Key = keyof (ApplicationStateType);

function set(key: Key, value: any) {
    return {
        type: APPLICATION_TYPES.SET,
        payload: { key, value }
    }
}

function getApplications() {
    return {
        type: APPLICATION_TYPES.GET_APPLICATIONS
    }
}

function setApplications(applications: any) {
    return {
        type: APPLICATION_TYPES.SET_APPLICATIONS,
        payload: { applications }
    }
}

function createApplication(workflowId: string, workflowTitle?: string) {
    return {
        type: APPLICATION_TYPES.CREATE_APPLICATION,
        payload: { workflowId, workflowTitle }
    }
}

function createApplicationByRootSlug(workflowSlug: string) {
    return {
        type: APPLICATION_TYPES.CREATE_APPLICATION_BY_ROOT_SLUG,
        payload: { workflowSlug }
    }
}

function updateApplication(id: string, application: Application) {
    return {
        type: APPLICATION_TYPES.UPDATE_APPLICATION,
        payload: { id, application }
    }
}

function destroyApplication(id: string) {
    return {
        type: APPLICATION_TYPES.DESTROY_APPLICATION,
        payload: { id }
    }
}

function continueApplication(id: string) {
    return {
        type: APPLICATION_TYPES.CONTINUE_APPLICATION,
        payload: { id }
    }
}

function getApplication(id: string, options?: { partialLoading?: boolean }) {
    return {
        type: APPLICATION_TYPES.GET_APPLICATION,
        payload: { id, options }
    }
}

function getLastOneApplication() {
    return {
        type: APPLICATION_TYPES.GET_LASTONE_APPLICATION,
        payload: {}
    }
}

function setApplication(application: Application) {
    return {
        type: APPLICATION_TYPES.SET_APPLICATION,
        payload: { application }
    }
}

function setLastOneApplication(application: Application) {
    return {
        type: APPLICATION_TYPES.SET_LASTONE_APPLICATION,
        payload: { application }
    }
}

function applicationFailed() {
    return {
        type: APPLICATION_TYPES.APPLICATION_FAILED,
    }
}

function publish(id: string) {
    return {
        type: APPLICATION_TYPES.PUBLISH,
        payload: { id }
    }
}

function sendSmsCode(id: string, data?: any) {
    return {
        type: APPLICATION_TYPES.SEND_SMS_CODE,
        payload: { id, data }
    }
}

function handleGetError() {
    return {
        type: APPLICATION_TYPES.HANDLE_GET_ERROR,
    }
}

function addApplicationPusherChannel(channel: string) {
    return {
        type: APPLICATION_TYPES.ADD_APPLICATION_PUSHER_CHANNEL,
        payload: { channel }
    }
}

function cancelApplication(id: string, from: 'list' | 'screen' = 'list') {
    return {
        type: APPLICATION_TYPES.CANCEL_APPLICATION,
        payload: { id, from }
    }
}

function sendCurrentSectionMagicLink(email: string) {
    return {
        type: APPLICATION_TYPES.SEND_CURRENT_SECTION_MAGIC_LINK,
        payload: { email }
    }
}

function assignCustomer(applicationId: string) {
    return {
        type: APPLICATION_TYPES.ASSIGN_CUSTOMER,
        payload: { applicationId }
    }
}


function getScoreResult(applicationId: string, scoreId: string) {
    return {
        type: APPLICATION_TYPES.GET_SCORE_RESULT,
        payload: { applicationId, scoreId }
    }
}

function setScoreResult(score?: any) {
    return {
        type: APPLICATION_TYPES.SET_SCORE_RESULT,
        payload: { score }
    }
}

function publishError() {
    return {
        type: APPLICATION_TYPES.PUBLISH_ERROR
    }
}

function getLastScore(applicationId: string, workflowId: string, stageId: string, applicationStatus?: ApplicationStatusType) {
    return {
        type: APPLICATION_TYPES.GET_LAST_SCORE,
        payload: { applicationId, workflowId, stageId, applicationStatus }
    }
}

function createApplicationByEventTrigger(
    applicationId: string,
    eventTriggerSlug?: string,
    answers?: {
        will_contribute?: boolean,
    }
) {
    return {
        type: APPLICATION_TYPES.CREATE_APPLICATION_BY_EVENT_TRIGGER,
        payload: { applicationId, eventTriggerSlug, answers }
    }
}

function getRejectedReasons(applicationId: string) {
    return {
        type: APPLICATION_TYPES.GET_REJECTED_REASONS,
        payload: { applicationId }
    }
}

function setRejectedReasons(rejectedReasons?: any) {
    return {
        type: APPLICATION_TYPES.SET_REJECTED_REASONS,
        payload: { rejectedReasons }
    }
}

function createSession(applicationId: string, session?: Application['session']) {
    return {
        type: APPLICATION_TYPES.CREATE_SESSION,
        payload: { applicationId, session }
    }
}

export const applicationActions = {
    set,
    getApplications,
    setApplications,
    getApplication,
    setApplication,
    createApplication,
    createApplicationByRootSlug,
    updateApplication,
    destroyApplication,
    continueApplication,
    applicationFailed,
    publish,
    sendSmsCode,
    handleGetError,
    addApplicationPusherChannel,
    cancelApplication,
    sendCurrentSectionMagicLink,
    assignCustomer,
    getLastOneApplication,
    setLastOneApplication,
    getScoreResult,
    setScoreResult,
    publishError,
    getLastScore,
    createApplicationByEventTrigger,
    getRejectedReasons,
    setRejectedReasons,
    createSession,
}