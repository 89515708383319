import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    hero: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        padding: '40px 10px 50px',
    },
    button: {
        color: theme.palette.primary.contrastText
    }
}));