import { tss } from "tss-react/mui"

export const useStyles = tss.create(({ theme }) => ({
    root: {
    },
    cardContent: {
        padding: 32,
    },
    contentWrapper: {
        display: 'flex',
        gap: 16,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            gap: 40
        }
    },
    userContainer: {
        display: 'flex',
        flexWrap: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
        }
    },
    avatar: {
        alignSelf: 'center',
        backgroundColor: theme.palette.primary.main,
        marginRight: 32,
        height: 80,
        width: 80,
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto',
            height: 50,
            width: 50,
            marginBottom: 24
        }
    },
    userDataContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 16,
        maxWidth: '100%',
        [theme.breakpoints.down('sm')]: {
            gap: 24,
        }
    },
    userNameText: {
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '24px',
        letterSpacing: '0.18px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            lineHeight: '22px',
            letterSpacing: '0.15px',
            textAlign: 'center',
        }
    },
    greetingText: {
        fontSize: 14,
        lineHeight: '22px',
        letterSpacing: '0.5px',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            lineHeight: '22px',
            letterSpacing: '0.5px',
            textAlign: 'center',
        }
    },
    contactContainer: {
        display: 'flex',
        gap: 32,
        '& > p': {
            display: 'flex',
            alignItems: 'center'
        },
        '& svg': {
            marginRight: 8,
            fill: theme.palette.primary.main,
            width: 16,
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: 16,
            margin: '0 auto',
        }
    },
}))