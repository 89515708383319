import { Grid } from "@mui/material";
import { useStyles } from "./styles"
import { ApplicationStatusComponent, ConsultantCard } from "../components";
import { NextStepsCard } from "../components/NextStepsCard";
import { useIsMobile } from "src/helpers";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

interface InReviewPageProps {
	status?: any;
}

export function InReviewPage({ status }: InReviewPageProps) {
	const queryParams: any = useParams()
	const { classes, cx } = useStyles();
	const isMobile = useIsMobile()
	const application = useSelector(state => state.applications.application);
	const isLoading = useSelector(state => state.applications.isLoading);

	return (
		<Grid
			id="in_review-hero"
			container
			spacing={isMobile ? 6 : 3}
			className={classes.hero}
		>
			<Grid item xs={12} md={6}>
				<ApplicationStatusComponent
					status={status || queryParams.status}
					isLoading={isLoading}
					application={application}
					homeButtonProps={{
						variant: 'contained',
						className: classes.button
					}}
				/>
			</Grid>

			<Grid item xs={12} md={6}>
				<ConsultantCard applicationOrigin="current" />
			</Grid>

			<Grid item xs={12} className={classes.stagesContainer}>
				<NextStepsCard
					application={application}
					isLoading={isLoading}
				/>
			</Grid>

		</Grid>
	)
}