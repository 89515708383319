import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { Alert, Loading, StagePage } from "src/components";

import { useDispatch, useSelector } from "react-redux";
import { applicationActions } from "src/store/application/actions";
import { applicationPaymentsActions } from "src/store/payments/actions";

import { PaymentsTable } from "./Table";
import { customTranslatePaymentSessionStatus, getAppraisalPayment } from "src/helpers/payments";

export function ApplicationPaymentsListPage() {
    const { applicationId }: any = useParams()
    const dispatch = useDispatch()
    const application = useSelector(state => state.applications.application)
    const isLoadingApplication = useSelector(state => state.applications.isLoading)
    const isLoadingPayments = useSelector(state => state.payments.isLoading)
    const payments = useSelector(state => state.payments.data)
    const appraisalPayment = getAppraisalPayment(payments)
    const appraisalPaymentStatus = customTranslatePaymentSessionStatus(appraisalPayment)

    useEffect(() => {
        if (applicationId)
            dispatch(applicationActions.getApplication(applicationId))
    }, [applicationId])

    useEffect(() => {
        if (application?.payment_external_id)
            dispatch(applicationPaymentsActions.getAll({
                queryParams: {
                    payment_external_id: application?.payment_external_id
                }
            }))
    }, [application?.payment_external_id])

    return (
        <StagePage
            title="Pagos"
            breadcrumbs={[
                { name: 'Inicio', to: '/' },
                { name: 'Pagos' }
            ]}
        >
            <Loading isLoading={isLoadingApplication || isLoadingPayments} variant="skeleton">

                <Grid container spacing={3}>
                    {(appraisalPayment?.id && appraisalPaymentStatus == 'pendiente') && (
                        <Grid item xs={12}>
                            <Alert
                                actionProps={{
                                    title: 'Realizar pago',
                                    linkProps: {
                                        to: `/home/applications/${applicationId}/payments/${appraisalPayment?.id}`
                                    }
                                }}
                            >
                                Paga el avalúo de la propiedad para continuar con la solicitud
                            </Alert>
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <PaymentsTable
                            applicationId={applicationId}
                            payments={payments}
                        />
                    </Grid>

                </Grid>
            </Loading>

        </StagePage>
    )
}