import { useEffect, useState } from "react"
import { Grid, Zoom } from "@mui/material"
import { ConfirmModal, Loading, StagePage } from 'src/components'
import { segment } from "src/helpers"
import { DashboardCard, ConsultantCard, RelatedApplicationsTabs } from "./components"
import _ from "lodash"

import { CancelApplicationDrawer } from "../Applications/CancelApplicationDrawer"
import { CoaccreditModal } from "./components/CoacreditModal"

import { useDispatch, useSelector } from "react-redux"
import { applicationActions, applicationCategoriesActions, applicationRejectedDocuments } from "src/store/application/actions"
import { creditsActions } from "src/store/credits/actions"
import { relatedApplcationsActions } from "src/store/relatedApplications/actions"
import { creditProposalsActions } from "src/store/creditProposals/actions"
import { applicationPaymentsActions } from "src/store/payments/actions"
import { useStyles } from "./styles"
import { Application } from "src/types"

export function HomeMain() {
	const { classes } = useStyles()
	const dispatch = useDispatch()

	const customer = useSelector(state => state.auth.customer)
	const canceledCategories = useSelector(state => state.applications.categories?.data || [])
	const lastOneApplication = useSelector(state => state.applications.lastApplication)
	const application = useSelector((state => state.applications.application))
	const isLoadingApplication = useSelector(state => state.applications.isLoading)
	const isSegmentLoaded = useSelector(state => state.auth.isSegmentLoaded)
	const isLoadingRejectedDocuments = useSelector(state => state.applications.rejectedDocuments.isLoading)

	const [cancelApplicationOpen, setCancelApplicationOpen] = useState(false)
	const [coaccreditOpen, setCoaccreditOpenOpen] = useState(false)
	const [selectedApplicationId, setSelectedApplicationId] = useState<string>("")
	const [destroyCoaccredited, setDestroyCoaccredited] = useState<{ open: boolean, application?: Application }>({ open: false })

	function getLastApplicationInfo() {
		if (lastOneApplication?.id) {
			dispatch(relatedApplcationsActions.getAll(lastOneApplication.id, {}))
			setSelectedApplicationId(lastOneApplication.id)
		}

		if (lastOneApplication?.credit_id) {
			dispatch(creditsActions.getOne(lastOneApplication.credit_id))
		}
	}

	useEffect(() => {
		fetchCancelledCategories()
		dispatch(applicationActions.getLastOneApplication())
	}, [])

	/**
	 * Fetches the related applications and the credit of the last application when the last application changes
	 */
	useEffect(() => {
		getLastApplicationInfo()
	}, [lastOneApplication?.id])

	/**
	 * Ensure the last application is fetched when the component mounts even if the last application is already in the store
	 * This is to ensure the last application is always up to date
	 */
	useEffect(() => {
		getLastApplicationInfo()
	}, [])

	useEffect(() => {
		if (selectedApplicationId) {
			dispatch(creditProposalsActions.getAll(selectedApplicationId, {}))
			dispatch(applicationRejectedDocuments.getRequiredCount(selectedApplicationId))
			dispatch(applicationActions.getApplication(selectedApplicationId, { partialLoading: true }))
		}
	}, [selectedApplicationId])

	useEffect(() => {
		if (application?.payment_external_id)
			dispatch(applicationPaymentsActions.getAll({
				queryParams: {
					payment_external_id: application?.payment_external_id
				}
			}))
	}, [application?.payment_external_id])

	useEffect(() => {
		isSegmentLoaded && segment.page('Home')
	}, [isSegmentLoaded])

	function fetchCancelledCategories() {
		if (canceledCategories.length > 0) {
			return
		}
		dispatch(applicationCategoriesActions.getAll({
			search: { status_eq: 'cancelled' },
			perPage: 200
		}))
	}

	return (
		<>
			<CoaccreditModal
				applicationId={lastOneApplication?.id!}
				open={coaccreditOpen}
				onClose={() => setCoaccreditOpenOpen(false)}
			/>

			<CancelApplicationDrawer
				application={lastOneApplication}
				open={cancelApplicationOpen}
				onClose={() => setCancelApplicationOpen(false)}
			/>

			<ConfirmModal
				open={destroyCoaccredited.open}
				cancelText="Cancelar"
				description={`¿Deseas eliminar esta solicitud de “${application?.workflow_title}”?`}
				onConfirm={() => {
					if (lastOneApplication?.id && destroyCoaccredited.application?.id) {
						dispatch(relatedApplcationsActions.unbind(lastOneApplication.id, destroyCoaccredited.application.id))
						setSelectedApplicationId(lastOneApplication?.id!)
					}
					setDestroyCoaccredited({ open: false })
				}}
				onCancel={() => setDestroyCoaccredited({ open: false })}
			/>

			<StagePage>
				<Loading
					isLoading={isLoadingApplication || isLoadingRejectedDocuments}
					variant="skeleton"
					skeletonsNumber={2}
				>
					<Grid container gap={'40px'}>

						<Grid item xs={12} style={{ position: 'relative' }}>
							<RelatedApplicationsTabs
								application={lastOneApplication}
								selectedApplicationId={selectedApplicationId}
								setSelectedApplicationId={setSelectedApplicationId}
							/>
							<DashboardCard
								customer={customer}
								application={selectedApplicationId == lastOneApplication?.id ? lastOneApplication : application}
								setCancelApplicationOpen={setCancelApplicationOpen}
								setCoaccreditOpen={setCoaccreditOpenOpen}
								isCurrentApplication={selectedApplicationId == lastOneApplication?.id}
								setDestroyCoaccredited={application => {
									setDestroyCoaccredited({ open: true, application })
								}}
							/>
						</Grid>

						{customer?.user && (
							<Zoom in>
								<Grid item xs={12} className={classes.consultantCard}>
									<ConsultantCard />
								</Grid>
							</Zoom>
						)}
					</Grid>
				</Loading>
			</StagePage >
		</>
	)
}