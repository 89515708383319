import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
	hero: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'space-between',
		padding: '40px 10px 50px',
	},
	stagesContainer: {
		marginTop: 60
	},
	button: {
		color: theme.palette.primary.contrastText
	}
}));