export const APPLICATION_TYPES = {
    SET: "APPLICATION/SET",
    GET_APPLICATIONS: "APPLICATION/GET_APPLICATIONS",
    SET_APPLICATIONS: "APPLICATION/SET_APPLICATIONS",
    GET_APPLICATION: "APPLICATION/GET_APPLICATION",
    SET_APPLICATION: "APPLICATION/SET_APPLICATION",
    CREATE_APPLICATION: "APPLICATION/CREATE_APPLICATION",
    UPDATE_APPLICATION: "APPLICATION/UPDATE_APPLICATION",
    DESTROY_APPLICATION: "APPLICATION/DESTROY_APPLICATION",
    CONTINUE_APPLICATION: "APPLICATION/CONTINUE_APPLICATION",
    APPLICATION_FAILED: "APPLICATION/APPLICATION_FAILED",
    PUBLISH: "APPLICATION/PUBLISH",
    SEND_SMS_CODE: "APPLICATION/SEND_SMS_CODE",
    HANDLE_GET_ERROR: "APPLICATION/HANDLE_GET_ERROR",
    ADD_APPLICATION_PUSHER_CHANNEL: "APPLICATION/ADD_APPLICATION_PUSHER_CHANNEL",
    CANCEL_APPLICATION: "APPLICATION/CANCEL_APPLICATION",
    SEND_CURRENT_SECTION_MAGIC_LINK: "APPLICATION/SEND_CURRENT_SECTION_MAGIC_LINK",
    ASSIGN_CUSTOMER: "APPLICATION/ASSIGN_CUSTOMER",
    GET_LASTONE_APPLICATION: "APPLICATION/GET_LASTONE_APPLICATION",
    SET_LASTONE_APPLICATION: "APPLICATION/SET_LASTONE_APPLICATION",
    GET_SCORE_RESULT: "APPLICATION/GET_SCORE_RESULT",
    SET_SCORE_RESULT: "APPLICATION/SET_SCORE_RESULT",
    PUBLISH_ERROR: "APPLICATION/PUBLISH_ERROR",
    GET_LAST_SCORE: "APPLICATIONS/GET_LAST_SCORE",
    CREATE_APPLICATION_BY_ROOT_SLUG: "APPLICATION/CREATE_APPLICATION_BY_ROOT_SLUG",
    CREATE_APPLICATION_BY_EVENT_TRIGGER: "APPLICATION/CREATE_APPLICATION_BY_EVENT_TRIGGER",
    GET_REJECTED_REASONS: "APPLICATION/GET_REJECTED_REASONS",
    SET_REJECTED_REASONS: "APPLICATION/SET_REJECTED_REASONS",
    CREATE_SESSION: "APPLICATION/CREATE_SESSION",
}