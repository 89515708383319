import { Application } from 'src/types'
import { Alert } from 'src/components'
import { getAlertPropsByApplication } from './helpers'

import { useSelector } from 'react-redux'
import { useApplicationStage, useIsKredi } from 'src/helpers'

interface ApplicationStatusAlertProps {
    application?: Application
}

export function ApplicationStatusAlert({ application: _application }: ApplicationStatusAlertProps) {
    const isKredi = useIsKredi()

    const customer = useSelector(state => state.auth.customer)
    const application = _application || useSelector(state => state.applications?.lastApplication)
    const credit = useSelector(state => state.credits.credits?.credit)
    const creditProposals = useSelector(state => state.creditProposals?.data || [])
    const requiredDocumentsCount = useSelector(state => state.applications.rejectedDocuments?.count || 0)
    const payments = useSelector(state => state.payments.data)

    const {
        isPriority,
        canContinue,
        currentSectionLink,
    } = useApplicationStage({ useLastOneApplication: true, application })

    const alertProps = getAlertPropsByApplication({
        application,
        isPriority,
        canContinue,
        currentSectionLink,
        creditProposals,
        hasRequiredDocuments: requiredDocumentsCount > 0,
        isKredi,
        hasActiveCredit: credit?.status === 'active',
        currentCustomerId: customer?.id,
        payments
    })

    return <Alert {...alertProps} />
}