import { Navigate, Routes as RRDRoutes, Route } from "react-router-dom"
import { Route as RouteType } from "src/types"
import _ from "lodash"
import { useSelector } from "react-redux"
import { cookies, getCookieName } from "src/helpers"

type RoutesProps = {
    routes: RouteType[]
    notFoundPage?: any
}

/**
 * Map routes to reac-routed-dom Route
 * @param param0 
 * @returns 
 */
export function Routes({ routes, notFoundPage: NotFoundPage }: RoutesProps) {
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);

    return (
        <RRDRoutes>
            {routes.map(({ component: Component, ...route }, index) => {
                if (_.isArray(route.path)) {
                    return route.path.map((path, index) => (
                        <Route
                            key={index}
                            index={route.isParent}
                            path={path}
                            element={route.isParent
                                ? isAuthenticated && Boolean(cookies.get(getCookieName()))
                                    ? <Component />
                                    : <Navigate to="/magic_link" replace />
                                : <Component />
                            }
                        />
                    ))
                } else {
                    return (
                        <Route
                            key={index}
                            index={route.isParent}
                            path={route.path}
                            element={route.isParent
                                ? isAuthenticated && Boolean(cookies.get(getCookieName()))
                                    ? <Component />
                                    : <Navigate to="/magic_link" replace />
                                : <Component />
                            }
                        />
                    )
                }
            })}

            {NotFoundPage && (
                <Route
                    path="*"
                    element={<Navigate to="/" />}
                />
            )}
        </RRDRoutes>
    )
}