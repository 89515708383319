import React from 'react'
import { tss } from "tss-react/mui";


export const useStyles = tss.create(({ theme }) => ({
    content: {
        padding: 40,
        [theme.breakpoints.down('sm')]: {
            padding: 16,
        }
    },
    button: {
        width: 132,
        height: 46,
        padding: '14px 0',
        [theme.breakpoints.down('sm')]: {
            width: 135,
            height: 44,
        }
    },
    title: {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: theme.palette.primary.dark,
        paddingBottom: 8
    },
    description: {
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        color: '#242C37',
        marginBottom: '32px',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            fontSize: 14
        }
    },
    backdrop: {
        backdropFilter: 'blur(8px)',
        background: 'rgba(117, 139, 183, 0.12)'

    },
    paper: {
        border: '1px solid #CFD5E2',
        boxShadow: '0px 5px 22px rgba(57, 74, 97, 0.12)',
        borderRadius: 12,
        [theme.breakpoints.down('sm')]: {
            margin: 16,
        }
    }
}))