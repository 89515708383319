export interface FileURLType {
    url: string
    filename: string
}

/**
 * Returns the download urls from the evaluated values
 * @param evaluatedValues 
 * @returns 
 */
export function getDownloadUrlsFromEvaluatedValues(evaluatedValues?: Record<string, string>, attachmentUrl?: string): FileURLType[] {
    if (attachmentUrl) return [{
        url: attachmentUrl,
        filename: getFilenameFromURL(attachmentUrl)
    }]

    if (!evaluatedValues) return []
    return Object.entries(evaluatedValues)
        .filter(([key, value]) => (
            (
                !key.includes('report_file_url') &&
                !value?.includes('report_file_url') &&
                !key.includes('incode') &&
                value &&
                value != 't'
            ) && (
                key.includes('file') ||
                key.includes('value') ||
                value?.includes?.('file') ||
                value?.includes?.('attachment')
            )
        ))
        .map(([_, value]) => ({
            url: value,
            filename: getFilenameFromURL(value)
        }))
}

export function getFilenameFromURL(url: string) {
    if (!url || url == "t") return '';
    let filename = url.split('/').pop() || '';

    if (filename.includes('identificacion') && filename.includes('back'))
        filename = `Identificación oficial (trasera).${filename.split('.').pop()}`;
    else if (filename.includes('identificacion') && filename.includes('front'))
        filename = `Identificación oficial (frontal).${filename.split('.').pop()}`;

    return filename
}

export function downloadFiles(files: FileURLType[]) {
    files.forEach(({ url, filename }) => {
        const link = document.createElement('a')
        link.href = url
        link.download = filename
        link.target = '_blank'
        link.click()
    })
}