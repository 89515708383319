import { TableColumnType } from "src/components";
import { Address } from "src/types";

export const addressColumns: TableColumnType<Address>[] = [

    {
        key: 'street_number',
        label: 'Calle y número',
        render: (value, row) => `${row.street} ${value}`
    },
    {
        key: 'neighborhood',
        label: 'Colonia'
    },
    {
        key: 'zipcode',
        label: 'CP'
    },
    {
        key: 'municipality',
        label: 'Municipio'
    },
    {
        key: 'state',
        label: 'Estado'
    },
]