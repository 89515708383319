import { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { authActions } from "src/store/auth/actions";

interface PathnamePersistorProps {
    isAuth: boolean
}

const NOT_ALLOWED_PATHS = [
    'signin',
    'oauth',
    'magic_link',
    'create_account',
    'session_expired',
    'login_again',
    'maintenance',
    'expired-link'
]

/**
 * Component that persists the pathname in the store 
 * when the user is not authenticated to redirect after login.
 * */
export function PathnamePersistor({ isAuth }: PathnamePersistorProps) {
    const dispatch = useDispatch()

    useEffect(() => {
        if (
            !isAuth &&
            location.pathname !== '/' &&
            !NOT_ALLOWED_PATHS.includes(location.pathname.split('/')[1])
        ) {
            console.log('PathnamePersistor', location.pathname)
            dispatch(authActions.set('pathToRedirect', location.pathname))
        }
    }, [isAuth, location.pathname])

    return <Fragment />;
}