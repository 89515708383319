import { Button, ButtonProps, Grid } from "@mui/material"
import { useStyles } from "./styles"
import { ChevronLeft as LeftIcon } from "@mui/icons-material";

import { useDispatch } from "react-redux"
import { useFormBuilderContext } from "@kredimx/form-builder"
import { questionActions } from "src/store/questions/actions"
import { useIsMobile } from "src/helpers"
import { Fragment } from "react"

interface FormCustomActionsProps {
    hideCancel: boolean
    cancelButtonProps: ButtonProps
    submitButtonProps?: ButtonProps
}

/**
 * Custom actions for formbuilder, to include "Volver al resumen" button
 */
export function FormCustomActions({ hideCancel, cancelButtonProps, submitButtonProps }: FormCustomActionsProps) {
    const { classes, cx } = useStyles()
    const isMobile = useIsMobile('md')
    const dispatch = useDispatch()
    const { currentValues, isValid } = useFormBuilderContext()

    const { className: submitButtonClassName, ...restSubmitButtonProps } = submitButtonProps || {}
    const { className: cancelButtonClassName, ...restCancelButtonProps } = cancelButtonProps || {}

    return (
        <>
            {hideCancel ? <div /> : (
                <Button
                    {...restCancelButtonProps}
                    startIcon={<LeftIcon />}
                    className={cx(classes.cancelButton, cancelButtonClassName)}
                >
                    Volver
                </Button>
            )}

            <Grid
                className={classes.ctaButtons}
                component={isMobile ? Fragment : 'div'}
            >
                <Button
                    {...restSubmitButtonProps}
                    className={cx(classes.submitButton, submitButtonClassName)}
                    color="primary"
                    variant={isMobile ? "text" : "contained"}
                    type="submit"
                    disabled={!isValid}
                >
                    Siguiente
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.summaryButton}
                    disabled={!isValid}
                    onClick={(e: any) => {
                        dispatch(questionActions.saveAnswers(currentValues, { handleSummary: true }))
                    }}
                >
                    Volver al resumen
                </Button>
            </Grid>

        </>
    )
}