import { FormBuilder, StagePage } from 'src/components';
import { fields, initialValues } from "./fields";
import { RootState } from "src/store";
import { useSelector, useDispatch } from "react-redux";
import { Customer } from 'src/types';
import { customerActions } from 'src/store/customer/actions';
import { useIsMobile, segment } from 'src/helpers';
import { useEffect } from 'react';
import { useStyles } from './styles';
import { Card } from '@mui/material';
import { providersActions } from 'src/store/providers/actions';

export function PersonalData() {
	const dispatch = useDispatch();
	const { classes, cx } = useStyles()
	const customer = useSelector((state: RootState) => state.auth.customer);
	const { data: states, isLoading: isLoadingStates } = useSelector((state: RootState) => state.providers.states);
	const update = (customer: Customer) => dispatch(customerActions.update(customer));
	const isMobile = useIsMobile()

	useEffect(() => {
		segment.track('Customer', 'Viewed', 'PersonalData')
	}, [])

	function onSubmit(values: any) {
		segment.track('Customer', 'Updated', 'PersonalData')
		update(values);
	}

	return (
		<StagePage
			title="Datos personales"
			className={classes.stagePage}
			containerProps={{
				md: 8
			}}
			breadcrumbs={[
				{
					name: "Perfil",
					to: "/home/profile"
				},
				{
					name: "Datos personales",
					to: ""
				},
			]}
		>
			<Card className={classes.card} elevation={0}>
				<FormBuilder
					fields={fields}
					onSubmit={onSubmit}
					initialValues={customer.id ? customer : initialValues}
					submitCaption="Guardar cambios"
					hideCancel
					hideHelpers
					actionContainerProps={{
						justifyContent: isMobile ? 'center' : 'flex-end',
					}}
					submitButtonProps={{
						dataCy: 'submit-button',
						className: classes.submitButton
					}}
					cancelButtonProps={{
						className: classes.deleteButton
					}}
					enabledEnterSubmit={false}
					specialInputsProps={{
						stateInputProps: {
							isLoading: isLoadingStates,
							states: states || [],
							getStates: (countryCode?: string) => dispatch(providersActions.getStates(countryCode)),
							setStates: (states?: any[]) => dispatch(providersActions.setStates(states)),
						},
					}}
					hideButton
					disableOptionalLabel
					showValidationCheckIcon={false}
				/>
			</Card>
		</StagePage>
	)
}