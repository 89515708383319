import { getInitialValues } from "@kredimx/form-builder";
import { Employment, EmploymentResourceType } from "src/types";
import _ from "lodash"

export const initialValues = ({
    fields,
    isNew,
    employment,
    resourceType,
    isKredi,
    values
}: {
    fields: any[],
    isNew: boolean,
    resourceType?: EmploymentResourceType,
    employment?: Employment,
    values?: any,
    isKredi?: boolean
}) => {
    if (resourceType === 'creditParticipant') {
        const {
            activity_type,
            economic_activity_cnbv,
            economic_activity_sat,
            income_source,
            profession,
            salary_scheme,
            phones,
        } = employment || {}

        const defaultPhone = phones?.find(phone => phone.default)

        const _values = isNew ? {} : {
            ...employment,
            activity_type: activity_type?.external_id,
            economic_activity_cnbv: economic_activity_cnbv?.external_id,
            economic_activity_sat: economic_activity_sat?.external_id,
            income_source: income_source?.external_id,
            profession: profession?.external_id,
            salary_scheme: salary_scheme?.external_id,
            default_phone: {
                phone: defaultPhone?.number || '',
                phone_country_code: defaultPhone?.code_area ? `+${defaultPhone?.code_area}` : ''
            }
        }

        return {
            ...getInitialValues(fields, _values)
        }
    } else {
        const _values = isNew ? {
            ...employment,
            ...values,
            nationality: isKredi ? '' : 'Mexico',
        } : {
            ...employment,
            ...values,
            payment_frequency: employment?.checking_method != 'tax_return' ? employment?.payment_frequency : '',
            filing_frequency: employment?.checking_method == 'tax_return' ? employment?.filing_frequency : '',
            net_salary: employment?.net_salary || 0,
            // checking_method: _.isArray(employment?.checking_method) && employment?.checking_method.length! >= 0
            //     ? employment?.checking_method[0]
            //     : employment?.checking_method || ''
        }

        return {
            ...getInitialValues(fields, _values)
        }
    }
}