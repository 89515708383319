import { GetOptionsType } from "src/types"
import { RELATED_APPLICATIONS_TYPES } from "./types"

function getAll(applicationId: string, params: GetOptionsType) {
	return {
		type: RELATED_APPLICATIONS_TYPES.GET_ALL,
		payload: { applicationId, params }
	}
}

function setAll(data: any[], meta: any) {
	return {
		type: RELATED_APPLICATIONS_TYPES.SET_ALL,
		payload: { data, meta }
	}
}

function create(applicationId: string, params: any) {
	return {
		type: RELATED_APPLICATIONS_TYPES.CREATE,
		payload: { params, applicationId }
	}
}

function unbind(applicationId: string, relatedApplicationid: string) {
	return {
		type: RELATED_APPLICATIONS_TYPES.UNBIND,
		payload: { applicationId, relatedApplicationid }
	}
}

export const relatedApplcationsActions = {
	create,
	setAll,
	getAll,
	unbind
}