import { Application } from "src/types"

export function getPixelAttributeName(path: string): string | undefined {
    return path.split('.').pop()
}

/**
 * Returns the pixel tracking URL with the application attribute value
 * @param application 
 * @param domain 
 * @param path 
 * @param attribute 
 * @returns 
 */
export function getPixelTrackingUrl(
    application?: Application,
    domain?: string,
    path?: string,
    attribute?: string
): string {
    const attributeName = getPixelAttributeName(attribute!)
    const attributeValue = application?.external_data?.[attributeName as keyof Application]

    return attributeValue ? `https://${domain}${path?.replace(`:${attributeName}`, attributeValue)}` : ''
}