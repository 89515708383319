import { call, put, takeLatest, select, takeLeading, takeEvery } from "redux-saga/effects";
import { getShownQuestions, sagaWrapper, getKrediParams, getApplicationSegmentProperties, segment, getLogRocketSessionUrl, normalizer, navigate } from "src/helpers";
import { ApplicationService, Service } from "src/services";
import { Action, Response, Application, Field, SectionField, CountryStateType } from "src/types";
import { RootState } from "..";
import { setApplication } from "../application/sagas";
import { QUESTION_TYPES } from "./types";
import { setToken } from "../auth/sagas";
import queryString from 'query-string';
import _ from "lodash";
import { snackbarActions } from "src/store/snackbar/actions";
import { questionActions } from "./actions";
import { applicationActions } from "../application/actions";
import { authActions } from "../auth/actions";

const addressService = new Service('addresses', { isOptionalToken: true });

function* getQuestions({ payload }: Action) {
	const { applicationId } = payload;
	const response: Response = yield call(ApplicationService.allPath, `${applicationId}/questions`, {});
	yield put(questionActions.setQuestions(response.data));
}

function* getConditionedQuestions({ payload }: Action) {
	const { fieldId, values } = payload;
	const application: Application = yield select((state: RootState) => state.applications.application);
	const response: Response = yield call(ApplicationService.getConditionedQuestions, application.id!, {
		section_field_id: fieldId,
		answers: values
	});

	//SET ONLY QUESTIONS TO SHOW
	const questions: Field[] = yield select((state: RootState) => state.questions.data);
	yield put(questionActions.setQuestions(getShownQuestions(questions, response.data?.shownFields, response.data?.hiddenFields)))
}

function* saveAnswers({ payload }: Action) {
	const { answers, options } = payload;
	const { application } = yield select((state: RootState) => state.applications);
	let logRocketUrl: string = yield select((state: RootState) => state.auth.logRocketSession);
	const { referral_code, utm_data } = getKrediParams();
	yield put(questionActions.answersBackup(answers))

	if (_.isEmpty(logRocketUrl)) {
		logRocketUrl = getLogRocketSessionUrl()
		yield put(authActions.setLogRocketSession(logRocketUrl))
	}

	const response: Response = yield call(ApplicationService.update, application.id!, {
		application_workflow: {
			answers_dataset: answers,
			session: {
				screen_recording: logRocketUrl,
				utm_data,
				code: referral_code
			}
		}
	});

	const applicationProperties: object = yield call(getApplicationSegmentProperties, 'Application Updated');
	const values = {};
	if (payload.answers) {
		Object.entries(payload.answers).forEach(([key, value]) => {
			//@ts-ignore
			values[key] = value;
		})
	}
	segment.track('Application', 'Updated', {
		...applicationProperties,
		updated_resource: 'section',
		values
	})

	if (response.meta?.token) {
		yield call(
			setToken,
			normalizer(response.meta.token),
			{ disable: true }
		);
		yield put(snackbarActions.add('success', '¡Hemos creado tu cuenta!'));
	}

	if ((response.data?.next_section == null && options?.isLastSection) || options?.handleSummary) {
		const { from } = queryString.parse(window.location.search);

		yield put(applicationActions.set('isUpdating', false));
		yield put(applicationActions.set('showSummary', false));
		yield navigate.to(`/application/${application.id}/${application.current_stage?.slug}/summary${from ? '?from=' + from : ''}`)
	} else {
		yield call(setApplication, response.data);
	}
}

function* onlySaveAnswers({ payload }: Action) {
	const { answers } = payload;
	const application: Application = yield select((state: RootState) => state.applications.application)

	let logRocketUrl: string = yield select((state: RootState) => state.auth.logRocketSession);
	const { referral_code, utm_data } = getKrediParams();
	yield put(questionActions.answersBackup(answers))

	if (_.isEmpty(logRocketUrl)) {
		logRocketUrl = getLogRocketSessionUrl()
		yield put(authActions.setLogRocketSession(logRocketUrl))
	}

	const response: Response = yield call(ApplicationService.update, application.id!, {
		application_workflow: {
			only_save: true,
			answers_dataset: answers,
			session: {
				screen_recording: logRocketUrl,
				utm_data,
				code: referral_code
			}
		}
	});
	yield call(setApplication, response.data);
}

function* getAddressByZipCode({ payload }: Action) {
	const { zipCode, keys, fieldKey, currentValues, isValid } = payload;
	const questions: SectionField[] = yield select((state: RootState) => [...state.questions.data || []]);
	const application: Application = yield select((state: RootState) => state.applications.application);
	const states: CountryStateType[] = yield select((state: RootState) => state.providers.states.data || []);
	const current_values = { ...application.current_values, ...currentValues };

	try {
		const countryKey = keys.find(([responseKey, inputKey]: any[]) => responseKey === 'country')?.[1];
		if (!['Mexico', 'México'].includes(current_values[countryKey] || '') && current_values[countryKey] != '') return

		let response: Response = {}

		if (isValid) {
			response = yield call(addressService.patchPath, 'zip_code_data', {
				zip_code: {
					code: zipCode,
					country: currentValues[countryKey] || ''
				}
			});
		}

		const data = response?.data || {};

		keys.filter(([key, _]: any) => key != 'country').forEach(([responseKey, inputKey]: any[]) => {
			const question = questions.find(question => question.key === inputKey);

			if (_.isArray(data[responseKey])) {
				if (question) {
					const options: any[] = data[responseKey]
						.filter((value: string) => value?.length > 0)
						.map((value: string) => ({ value, caption: value })) || [];

					if (responseKey === 'country') {
						question.field_type = 'select_field';
					} else {
						question.field_type = options.length > 0 ? 'select_field' : 'text_field';
					}

					question.config = {
						...question.config,
						options,
						disableRenderRadio: true
					}

					if (options.length == 1) {
						current_values[inputKey] = options[0].value;
					} else {
						current_values[inputKey] = current_values[inputKey] || ''
					}

					if (responseKey === "city" && options.length === 0) {
						question.required = false
					}

					if (options.length == 1) {
						current_values[inputKey] = options[0].value;
					} else {
						current_values[inputKey] = current_values[inputKey] || ''
					}
				}

			} else {
				const value = data[responseKey] || '';

				if (value.length > 0) {
					current_values[inputKey] = value;
				} else {
					current_values[inputKey] = current_values[inputKey] || ''
				}

				const disabled = Boolean(zipCode) &&
					data[responseKey].length > 0 &&
					['city', 'municipality', 'state'].includes(responseKey || '')

				if (question) {

					question.field_type = 'text_field' // value.length > 0 ? 'select_field' : ;
					question.disabled = disabled
					question.config = {
						...question.config,
						options: [{ value: value, caption: value }],
						disableRenderRadio: true
					}

					if (responseKey === "city" && value?.length === 0) {
						question.required = false
					}

					if (responseKey === 'state' && states.length > 0) {
						question.field_type = 'select_field'
						question.config = {
							...question.config,
							options: states.map(s => ({ value: s.name, caption: s.name })),
						}
					}
				}
			}

		});

	} catch (error: any) {

		keys.filter(([key, _]: any) => key != 'country').forEach(([responseKey, inputKey]: any[]) => {
			const question = questions.find(question => question.key === inputKey);

			if (question?.field_type === "select_field") {
				if (
					question?.config?.type !== "state_select" &&
					question?.config?.type !== "country_select"
				) {
					question.field_type = 'text_field';
				}

				question.config = {
					...question.config,
					disableRenderRadio: true
				}

				if (responseKey === 'state' && states.length > 0) {
					question.field_type = 'select_field'
					question.config = {
						...question.config,
						options: states.map(s => ({ value: s.name, caption: s.name })),
					}
				}
			}
			current_values[inputKey] = current_values[inputKey] || ''
		});

		if (error?.response?.data?.errors[0]?.message) {
			yield put(snackbarActions.add('error', error?.response?.data?.errors[0]?.message))
		}
	}

	yield put(applicationActions.setApplication({
		...application,
		section_fields: questions,
		current_values: {
			...current_values,
			[fieldKey]: zipCode
		}
	}));
}

function* cleanCountryInputs({ payload }: Action) {
	const { key, value, dependencies, currentValues, removeRequiredOnly } = payload;
	const application: Application = yield select((state: RootState) => state.applications.application);
	const questions: SectionField[] = yield select((state: RootState) => [...state.questions.data]);
	const current_values = { ...application.current_values, ...currentValues };

	Object.entries(dependencies || {}).forEach(([key, inputKey]: any[]) => {
		const question = questions.find(question => question.key === inputKey);
		const shouldBeRequired = !['neighborhood', 'city'].includes(key)

		if (removeRequiredOnly) {

			if (question) {
				question.required = shouldBeRequired;
			}

		} else {

			if (question) {
				question.field_type = ['state', 'zip_code'].includes(key) ? question.field_type : 'text_field';
				question.placeholder = key == 'state' ? 'Selecciona una opción' : question.placeholder;

				question.required = ['Mexico', 'México'].includes(value) ? true : shouldBeRequired;
				question.config = {
					...question.config,
					disableRenderRadio: true
				}
			}

			current_values[inputKey] = ""
		}

	});

	yield put(applicationActions.setApplication({
		...application,
		section_fields: questions,
		current_values: {
			...current_values,
			[key]: value
		}
	}));
}

function* handleZipCodeError() {
	const application: Application = yield select((state: RootState) => state.applications.application);
	yield put(applicationActions.getApplication(application.id!));
}

function* downloadFile({ payload }: Action) {
	const response: { blob: any } = yield call(fetch, payload.fileUrl)
	const blob: Blob = yield response.blob()
	const url = URL.createObjectURL(blob)

	const anchor = document.createElement('a')
	anchor.href = url
	anchor.download = payload.fileName
	document.body.appendChild(anchor)
	anchor.click()
	document.body.removeChild(anchor)

	URL.revokeObjectURL(url)
	yield put(snackbarActions.add('success', 'Se ha descargado archivo.'))
}

export function* questionSaga() {
	yield takeLatest(QUESTION_TYPES.GET_QUESTIONS, sagaWrapper(getQuestions));
	yield takeLatest(QUESTION_TYPES.SAVE_ANSWERS, sagaWrapper(saveAnswers, applicationActions.applicationFailed()));
	yield takeLeading(QUESTION_TYPES.GET_CONDITIONED_QUESTIONS, sagaWrapper(getConditionedQuestions));
	yield takeLatest(QUESTION_TYPES.GET_ADDRESS_BY_ZIP_CODE, sagaWrapper(getAddressByZipCode));
	yield takeLatest(QUESTION_TYPES.CLEAN_COUNTRY_INPUTS, sagaWrapper(cleanCountryInputs));
	yield takeLatest(QUESTION_TYPES.HANDLE_ZIP_CODE_ERROR, sagaWrapper(handleZipCodeError));
	yield takeLatest(QUESTION_TYPES.DOWNLOAD_FILE, sagaWrapper(downloadFile))
	yield takeLatest(QUESTION_TYPES.ONLY_SAVE_ANSWERS, sagaWrapper(onlySaveAnswers, applicationActions.applicationFailed()))
}