import { useSelector } from "react-redux"
import { getPixelTrackingUrl } from "./helpers"

export function ApplicationTracking() {
    const organization = useSelector(state => state.organization)
    const application = useSelector(state => state.applications.application)

    return (
        <>
            {(organization?.installed_apps?.pixel?.domain && application?.id) && (
                <img
                    height={1}
                    width={1}
                    src={getPixelTrackingUrl(
                        application,
                        organization.installed_apps.pixel.domain,
                        organization.installed_apps.pixel.path,
                        organization.installed_apps.pixel.attribute
                    )}
                    style={{ display: 'none' }}
                />
            )}

            {(organization?.installed_apps?.pixel_afiliacion?.domain && application?.id) && (
                <img
                    height={1}
                    width={1}
                    src={getPixelTrackingUrl(
                        application,
                        organization.installed_apps.pixel_afiliacion.domain,
                        organization.installed_apps.pixel_afiliacion.path,
                        organization.installed_apps.pixel_afiliacion.attribute
                    )}
                    style={{ display: 'none' }}
                />
            )}
        </>
    )
}