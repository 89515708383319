import { alpha } from "@mui/material";
import { tss } from "tss-react/mui";

export const useStyles = tss.create(({ theme }) => ({
    card: {
        backgroundColor: '#fff',
        border: 'none',
        padding: 32,
        borderRadius: 10,
        textAlign: 'center',
    },
    item: {
        display: 'flex',
        justifyContent: 'center'
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    name: {
        fontSize: 20,
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        marginBottom: 12,
    },
    nameDescription: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    contact: {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        '& svg': {
            height: 14,
        },
        '& p': {
            display: 'flex',
            alignItems: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            '& p': {
                width: '100%',
                wordBreak: 'break-all'
            },
        }
    },

    itemActions: {
        paddingTop: 32,
        '& a': {
            color: theme.palette.primary.main,
            textDecoration: 'none',
        },
        '& button': {
            [theme.breakpoints.down('sm')]: {
                padding: '8px 4px',
            }
        }
    },
    avatar: {
        height: 90,
        width: 90,
        backgroundColor: theme.palette.primary.light,
        [theme.breakpoints.down('sm')]: {
            height: 60,
            width: 60,
        }
    },
    skeleton: {
        maxWidth: '100%'
    }
}));
