import { useSelector } from "react-redux"

import {
	Drawer,
	Grid,
	DrawerProps,
	IconButton,
	Button,
	Typography,
} from "@mui/material"

import {
	ArrowBackIos as CloseIcon,
	MonetizationOnOutlined as MoneyIcon,
	FolderSharedOutlined as ApplicationIcon,
	PersonOutline as PersonIcon,
} from "@mui/icons-material"

import { HomeIcon, LogoutIcon } from "src/assets/icons"
import { Link } from "src/components/Link"
import { getCookieName, useIsKredi } from "src/helpers"
import { useStyles } from "./styles"

export interface MenuProps extends DrawerProps {
	open: boolean
	isAuthenticated: boolean
	cookies: any
	onClose: () => void
	logout: () => void
}

export const Menu = ({
	onClose,
	open,
	isAuthenticated,
	cookies,
	logout,
	...rest
}: MenuProps) => {
	const { classes } = useStyles()
	const isKredi = useIsKredi()

	const organization = useSelector(state => state.organization)
	const lastOneApplication = useSelector(state => state.applications.lastApplication)

	function handleItemClick() {
		onClose()
	}

	return (
		<Drawer
			closeAfterTransition
			anchor="left"
			open={open}
			onClose={onClose}
			variant="temporary"
			elevation={0}
			PaperProps={{
				className: classes.paper
			}}
			{...rest}
		>
			<Grid
				container
				alignItems="center"
				justifyContent="space-between"
				className={classes.titleContainer}
			>
				<Link
					native={!isAuthenticated}
					to={!isAuthenticated && organization.website || "/"}
				>
					<img
						src={organization.logo}
						alt={`${organization.name} logo`}
						style={{
							maxHeight: 28,
							maxWidth: 124,
							aspectRatio: 'auto',
							display: 'block'
						}}
					/>
				</Link>

				<IconButton data-testid="close-button" size="small" onClick={onClose}>
					<CloseIcon className={classes.arrowCloseIcon} />
				</IconButton>
			</Grid>

			<Grid container className={classes.container}>
				{isAuthenticated && Boolean(cookies.get(getCookieName())) ? (
					<div className={classes.itemsContainer}>
						<div>
							<Link to="/home/main" onClick={handleItemClick}>
								<Typography>
									<HomeIcon /> Inicio
								</Typography>
							</Link>

							{(lastOneApplication?.credit_id && isKredi) && (
								<Link to="/home/credit" onClick={handleItemClick}>
									<Typography>
										<ApplicationIcon /> Créditos
									</Typography>
								</Link>
							)}

							{isKredi && (
								<Link to="/home/product_applications" onClick={handleItemClick}>
									<Typography>
										<MoneyIcon /> Simulaciones
									</Typography>
								</Link>
							)}

							<Link to="/home/profile" onClick={handleItemClick}>
								<Typography>
									<PersonIcon /> Perfil
								</Typography>
							</Link>
						</div>

						<Link className={classes.logoutLink} to="/magic_link" onClick={() => logout()}>
							<Typography>
								<LogoutIcon /> Cerrar sesión
							</Typography>
						</Link>
					</div>
				) : (
					<div style={{ display: "flex", flexDirection: "column", gap: '16px' }}>
						<Link to={location.pathname === "/magic_link" ? "/create_account" : "/magic_link"} onClick={handleItemClick}>
							<Button startIcon={<PersonIcon color="primary" />}>
								{location.pathname === "/magic_link" ? "Crear una cuenta" : "Iniciar sesión"}
							</Button>
						</Link>

						{(!isAuthenticated && location.pathname != "/") && (
							<Link to="/" onClick={handleItemClick}>
								<Button color="primary">
									Aplicar
								</Button>
							</Link>
						)}
					</div>
				)}
			</Grid>
		</Drawer>
	)
}
