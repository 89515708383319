import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { applicationActions } from "src/store/application/actions";
import { Application } from "src/types";
import _ from "lodash";
import { getKrediParams, getLogRocketSessionUrl } from "src/helpers";
import { authActions } from "src/store/auth/actions";

interface useBindLogRocketProps {
    status: string
    applicationId?: string
}

export function useBindLogRocket({
    status,
    applicationId
}: useBindLogRocketProps) {
    const dispatch = useDispatch();
    const logRocketSession = useSelector(state => state.auth.logRocketSession);

    useEffect(() => {
        if (_.isEmpty(logRocketSession)) {
            const logRocketSession = getLogRocketSessionUrl();
            dispatch(authActions.setLogRocketSession(logRocketSession))
        }

        if (status && applicationId && logRocketSession) {
            const { referral_code, utm_data } = getKrediParams();

            dispatch(applicationActions.createSession(applicationId, {
                screen_recording: logRocketSession,
                utm_data,
                code: referral_code
            }))
        }
    }, [status, applicationId, logRocketSession])
}