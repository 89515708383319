import { Grid, Typography } from "@mui/material";
import { Fragment, useEffect } from "react";
import { useStyles } from "./styles"

import { useDispatch, useSelector } from "react-redux";
import { applicationActions } from "src/store/application/actions";

interface DashboardCardRejectedReasonsProps {
    applicationId: string
}

export function DashboardCardRejectedReasons({ applicationId }: DashboardCardRejectedReasonsProps) {
    const { classes } = useStyles()
    const dispatch = useDispatch()
    const rejectedReasons = useSelector(state => state.applications.rejectedReasons?.data)

    useEffect(() => {
        if (applicationId) {
            dispatch(applicationActions.getRejectedReasons(applicationId))
        }

        return () => {
            dispatch(applicationActions.setRejectedReasons())
        }
    }, [applicationId])

    return rejectedReasons?.length == 0 ? <Fragment /> : (
        <Grid container className={classes.container}>
            <ul>
                {rejectedReasons?.map(({ id, ko_label, comments }) => (
                    <li key={id} className={classes.reason}>
                        <div className={classes.reasonTitle}>
                            <Typography> {ko_label} </Typography>
                        </div>
                        <Typography
                            className={classes.reasonDescription}
                            dangerouslySetInnerHTML={{ __html: comments }}
                        />
                    </li>
                ))}
            </ul>
        </Grid>
    )
}