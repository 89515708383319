import { useEffect, ComponentType } from "react"
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { cookies, getCookieName, isValidId, segment } from "src/helpers";
import { useStyles } from "./styles";

import { useSelector, useDispatch } from "react-redux";
import { authActions } from "src/store/auth/actions";
import { applicationActions } from "src/store/application/actions";
import { customerActions } from "src/store/customer/actions";

import { RejectedPage } from "./Rejected"
import { ApprovedPage } from "./Approved";
import { InReviewPage } from "./InReview";
import { PreapprovedPage } from "./Preapproved";
import { Breadcrumbs, Loading } from "src/components";
import { segmentEvents } from "src/helpers/segmentEvents";
import { Employment } from "src/types";
import { getDefaultCustomerEmployment } from "../../helpers";
import { ApplicationTracking } from "../../Tracking";
import { useBindLogRocket } from "./helpers";

const responsesPages: { [key: string]: ComponentType<any> } = {
    approved: PreapprovedPage,
    rejected: RejectedPage,
    in_review: InReviewPage,
    pre_approved: PreapprovedPage,
    unreviewed: InReviewPage
}

export function ApplicationStatusResponsePage() {
    const { applicationId, status }: any = useParams();
    const { classes } = useStyles();
    const dispatch = useDispatch();
    useBindLogRocket({ status, applicationId });

    const scoringData = useSelector(state => state.auth.scoringSocketData);
    const application = useSelector(state => state.applications.application);

    const stage = application.applicationWorkflowStages?.find(stage => stage.stage_id === application.current_stage?.id) || {};
    const section = application.currentStageSections?.find(section => section.section_id === application.current_section?.id) || {};

    const Component = (
        Boolean(status)
            ? responsesPages[status]
            : responsesPages[scoringData?.action_tag
                ? (scoringData?.action_tag == 'comite' || scoringData?.action_tag == 'committee'
                    ? 'in_review'
                    : scoringData?.action_tag)
                : scoringData?.message || 'in_review']
    ) || LoadingComponent;

    function LoadingComponent() {
        return <Loading isLoading variant="circular" />
    }

    useEffect(() => {
        dispatch(applicationActions.set('isLoading', true));

        if (isValidId(applicationId)) {
            setTimeout(() => {
                dispatch(applicationActions.getApplication(applicationId));
            }, 5000)
        }

    }, [applicationId])

    useEffect(() => {
        if (isValidId(applicationId) && scoringData?.score_id) {
            dispatch(applicationActions.getScoreResult(applicationId, scoringData?.score_id))
        }
    }, [applicationId, scoringData?.score_id])

    useEffect(() => {
        if (scoringData?.message === 'rejected') {
            segment.track('Application', 'Rejected', {
                id: applicationId,
                action: 'Application Rejected',
            })
        }
    }, [scoringData?.message])

    useEffect(() => {
        dispatch(customerActions.set('isLoading', true))
        setTimeout(() => {
            if (cookies.get(getCookieName())) {
                dispatch(authActions.me());
            }
        }, 8000)
    }, []);

    useEffect(() => {
        (async () => {
            if ((status === 'approved' || status === 'pre_approved') && application?.id) {
                let predeterminedEmployment: Employment = {}
                predeterminedEmployment = await getDefaultCustomerEmployment()

                segment.nativeTrack(segmentEvents.APPLICATION_APPROVED, {
                    action: 'Application Pre-Approved',
                    status: 'pre_approved',
                    category: application?.workflow_title,
                    stage: application?.current_stage?.name,
                    stage_sort_order: stage.sort_order,
                    section: application?.current_section?.title,
                    section_sort_order: section?.sort_order,
                    requested_amount: application?.requested_amount,
                    approved_amount: application?.approved_amount,
                    credit_amount: application?.credit_amount,
                    location: 'Web App',
                    label: 'pre_approved',
                    revenue: predeterminedEmployment?.net_salary,
                    currency: 'MXN',
                    email: application?.customer?.email || '',
                    phone: `${application?.customer?.phone_with_country_code?.phone_country_code || ''}${application?.customer?.phone_with_country_code?.phone || ''}`,
                })
            }
        })()
    }, [status, application?.id, stage?.id, section?.id])

    return (
        <Grid container className={classes.root}>
            <Grid container className={classes.mainContainer}>
                <Grid container className={classes.container}>
                    <ApplicationTracking />
                    <Component status={scoringData?.action_tag || status || scoringData?.message} />
                </Grid>
            </Grid>
        </Grid>
    )
}